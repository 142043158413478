<template>
  <div class="modal">
    <div class="backdrop" @click="close" />
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="close">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <div class="row">
            <p class="title">TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE</p>
          </div>
          <div class="p-4 md:mt-6">
            <ul>
              <li class="title">1. QUEM SOMOS</li>
              <li>1.1. Tutu Digital é uma plataforma online de prestação de serviços de democratização de financiamento
                (crowdfunding), na modalidade investimentos e financiamentos de parte a parte (peerto-peer lending),
                permitindo assim que investidores (“Investidor(es)”) e tomadores de empréstimos (“Tomador(es)” e, em
                conjunto com os Investidores, “Usuários”) entrem em contato, de modo que os primeiros possam atender a
                demanda de crédito dos segundos (“Plataforma Tutu”).</li>
              <li>1.2. A <b>TUTU</b> não realiza as operações de empréstimo e/ou investimento diretamente, atuando como
                procuradora de seus usuários (i) na contratação de empréstimos perante a instituição financeira parceira,
                e (ii) na aquisição de títulos de investimento (Certificados de Depósitos Bancários – CDBs ou Cédulas de
                Crédito Bancário – CCBs) pelos Investidores, sendo essas operações realizadas no âmbito do mercado de
                crédito.</li>
              <li>1.3. Os Investidores e Tomadores devem cumprir com os termos e condições da Plataforma Tutu e com os
                requisitos necessários para estabelecimento da relação contratual, conforme estabelecidos nestes termos e
                condições de uso e política de privacidade (“Termos e Condições de Uso e Política de Privacidade”), bem
                como com os Termos e Condições do Investidor e Tomador, disponíveis em <a class="underline text-blue-700"
                  target="_blank" href="https://www.tutudigital.com.br">www.tutudigital.com.br</a>.</li>
              <li>1.4. Para determinados investimentos na Plataforma Tutu, o Investidor deverá, eventualmente, também
                atender aos critérios para sua qualificação de Investidor, como aos critérios de suitability (adequação de
                perfil) da Plataforma Tutu.</li>
              <li>1.5. Maiores informações estão disponíveis na página de acesso <a class="underline text-blue-700"
                  target="_blank" href="https://www.tutudigital.com.br">www.tutudigital.com.br</a>.</li>
              <li>1.6. As referências nestes termos e condições para “nós”, e “nosso”, significa uma referência à TUTU
                DIGITAL TECNOLOGIA LTDA. ou à TD CORRESPONDENTE BANCÀRIO LTDA. (“Tutu”). As referências a “sistemas” ou
                “Plataforma Tutu”, ou “site”, significa uma referência a um serviço prestado por nossos sistemas ou
                quaisquer outros serviços online prestados por nós e todos os dados gerenciados, exibidos ou transmitidos
                a partir de tais serviços. As referências a “você” e “seu” significa uma referência a um Usuário da
                Plataforma Tutu.</li>
            </ul>
            <ul>
              <li class="title">2. USO DA PLATAFORMA</li>
              <li>2.1. Ao usar a Plataforma Tutu, e os serviços prestados através dela ou por quaisquer outros sistemas
                fornecidos pela Tutu, <b>você deverá, necessariamente, confirmar que leu, entendeu e concordou com estes
                  Termos e Condições de Uso e Política de Privacidade, bem como com os Termos e Condições do Investidor e
                  Tomador</b> (conforme alterado de tempos em tempos). Os presentes Termos e Condições de Uso e Política
                de Privacidade constituem um contrato vinculativo e de valor legal entre você e a Tutu ao se utilizar da
                Plataforma Tutu.</li>
              <li>2.2. Estes Termos e Condições de Uso e Política de Privacidade também descrevem como e quando nós
                coletamos, utilizamos e compartilhamos suas informações através da Plataforma Tutu e outros serviços que
                disponibilizamos aos Usuários. Ao utilizar a Plataforma Tutu e outros de nossos serviços, você concorda
                com a realização da coleta, transferência, armazenamento, divulgação e utilização de suas informações, tal
                como descrito nestes Termos e Condições de Uso e Política de Privacidade. Isso inclui quaisquer
                informações que você decida fornecer que seja considerada sensível sob a lei aplicável. </li>
              <li>2.3. Ao aceitar estes Termos e Condições de Uso e Política de Privacidade, você declara e garante que é
                maior de 18 (dezoito) anos e é responsável pelos atos realizados na Plataforma Tutu, bem como para os
                Termos e Condições de Uso e Política de Privacidade e demais termos e condições aplicáveis à Plataforma
                Tutu.</li>
              <li>2.4. Se você não concordar com estes Termos e Condições de Uso e Política de Privacidade ou se você tem
                menos de 18 (dezoito) anos de idade, você deve parar de usar a Plataforma Tutu imediatamente.</li>
            </ul>
            <ul>
              <li class="title">3. ALTERAÇÕES NOS TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE</li>
              <li>3.1. Periodicamente podem ser feitas alterações nestes Termos e Condições de Uso e Política de
                Privacidade. Quaisquer alterações nestes Termos e Condições de Uso e Política de Privacidade serão
                previamente informadas por e-mail e também publicadas na Plataforma Tutu, com prazo de antecedência de 30
                (trinta) dias da efetiva modificação. Caso você continue utilizando a Plataforma Tutu após as alterações
                destes Termos e Condições de Uso e Política de Privacidade significará que você concordou com tais
                alterações e que está legalmente sujeito a tais alterações.</li>
            </ul>
            <ul>
              <li class="title">4. DEVER GERAL DE CONFIDENCIALIDADE</li>
              <li><b>4.1. Ao utilizar a Plataforma Tutu, você concorda que a Tutu terá acesso a certas informações
                  pessoais suas e/ou de sua empresa. Os presentes Termos e Condições de Uso e Política de Privacidade
                  esclarecem quais informações podem ser acessadas e como elas serão utilizadas e armazenadas pela Tutu.
                </b></li>
              <li>4.2. A Tutu protege os direitos de privacidade dos Usuários que se cadastrarem e/ou que de qualquer
                forma utilizarem a Plataforma Tutu, sejam eles Investidores ou Tomadores, nos termos da legislação
                aplicável e destes Termos e Condições de Uso e Política de Privacidade.</li>
              <li>4.3. Todas as informações que possam identificá-lo individualmente, direta ou indiretamente, incluindo
                suas informações de cadastro, dados armazenados em cookies e informações a respeito do seu acesso e
                utilização da Plataforma Tutu (incluindo informações de Protocolo de Internet) serão utilizadas de acordo
                com as finalidades constantes nestes Termos e Condições de Uso e Política de Privacidade e demais
                Políticas da Plataforma Tutu.</li>
              <li>4.4. Ao se cadastrar na Plataforma Tutu, você deve fornecer endereço de e-mail, nome de usuário e senha
                pessoal, para consequente criação de perfil pessoal de acesso (“Perfil Pessoal”) e emissão de senhas
                pessoais (“Senhas Pessoais”). Esses dados poderão ser utilizadas para que os Usuários acessem áreas
                restritas da Plataforma Tutu. </li>
              <li>4.5. Os Usuários concordam que a senha e login cadastrados na Plataforma Tutu são de uso pessoal 3 e
                intransferível, sendo confidenciais e de responsabilidade exclusiva do Usuário. O Usuário não poderá
                fornecer sua senha e login para qualquer terceiro. </li>
            </ul>
            <ul>
              <li class="title">5. Uso do Conteúdo Tutu</li>
              <li>5.1. Os direitos autorais de todo o material da Tutu, incluindo, sem limitações, textos, dados, artigos,
                design, códigos-fonte, softwares, fotos, imagens e outras informações (coletivamente, o “Conteúdo”), são
                de propriedade da Tutu ou licenciados para a Tutu pelo autor original do material e estão protegidos sob
                as Leis nº 9.610 de 19 de fevereiro de 1998 e Lei nº 9.609 de 19 de fevereiro de 1998. </li>
              <li>5.2. Os Usuários concordam em não reproduzir, duplicar, copiar, vender, revender ou explorar com ou sem
                finalidade comercial qualquer parte das informações e Conteúdo da Plataforma Tutu, bem como se obrigam a
                utilizar todo o Conteúdo da Plataforma Tutu de forma lícita, sendo vedada a reprodução, distribuição,
                transformação, comercialização ou modificação do Conteúdo, sem a prévia e expressa autorização da Tutu.
              </li>
              <li>5.3. Sem prejuízo de outras medidas que entender cabíveis, a Tutu poderá advertir, suspender ou
                cancelar, temporária ou definitivamente, o cadastro do Usuário, a qualquer tempo, iniciando as ações
                legais cabíveis ou outra medida cabível se o Usuário não cumprir qualquer dispositivo aqui previsto ou nos
                demais contratos da Plataforma Tutu. </li>
              <li>5.4. Ao se cadastrar na Plataforma Tutu, o Usuário concede à Tutu uma licença gratuita, para o uso de
                todo e qualquer conteúdo, foto ou imagem postado na Plataforma Tutu, de acordo com os fins estabelecidos
                nestes Termos e Condições de Uso e Política de Privacidade.</li>
            </ul>
            <ul>
              <li class="title">6. INFORMAÇÕES ESPECÍFICAS DOS USUÁRIOS</li>
              <li>6.1. Esta seção especifica quais informações devem ser disponibilizadas pelos Usuários e verificadas
                pela Tutu no ato do cadastramento dos Usuários e em preparação para as operações de empréstimo e/ou
                investimento, oportunidade em que outros documentos e informações poderão ser solicitados, inclusive para
                fins da análise de crédito do Tomador, bem como para realização de procedimentos de conheça-o-seu-cliente
                e anti-lavagem de dinheiro.</li>
              <li>6.2. Todas as informações requeridas estão de acordo com o disposto na Lei Complementar nº 105 de 10
                janeiro de 2001, a qual dispõe sobre o sigilo das operações de instituições financeiras. </li>
              <li>6.3. Os presentes Termos e Condições de Uso e Política de Privacidade e os demais documentos da Tutu
                estão de acordo com Código de Defesa do Consumidor, o Marco Civil da Internet e Lei de Sigilo Bancário.
              </li>
              <li>6.4. Você garante que todas as informações que fornecer na Plataforma Tutu são verdadeiras e precisas em
                todos os aspectos. Você nos informará se alguma das informações que você forneceu for alterada ou
                atualizada. A Plataforma Tutu não é responsável por qualquer informação não-verdadeira ou desatualizada
                fornecida pelos Usuários.</li>
            </ul>
            <ul>
              <li class="title">7. AS INFORMAÇÕES QUE COLETAMOS DE AMBOS TOMADORES E INVESTIDORES</li>
              <li>7.1. Ao acessar a página da Tutu e disponibilizar suas informações pessoais e/ou ao se inscrever na
                Plataforma Tutu como Tomador ou Investidor, <b>você está ciente e, neste ato, concorda expressamente que
                  as seguintes informações sejam coletadas e armazenadas pela Tutu</b>, além de outras informações que
                você nos forneça:</li>
              <li>(i) nome completo;</li>
              <li>(ii) data de nascimento;</li>
              <li>(iii) número de telefone residencial, telefone celular e endereço de e-mail; </li>
              <li>(iv) senha de acesso escolhida e respostas às “perguntas de segurança” disponibilizadas na página de
                acesso;</li>
              <li>(v) detalhes referentes aos dados da conta bancária e cartão de crédito utilizado para realização da
                operação perante a Plataforma Tutu;</li>
              <li>(vi) mensagens trocadas com a Plataforma Tutu via correio ou e-mail;</li>
              <li>(vii) comentários e quaisquer outras informações disponibilizadas nos grupos de discussão e blogs da
                Plataforma Tutu;</li>
              <li>(viii) informações referentes aos serviços solicitados e atividades realizadas na Plataforma Tutu; e
              </li>
              <li>(ix) os questionários requeridos pela Plataforma Tutu.</li>
              <li>7.2. Além das informações acima indicadas, você está ciente e, neste ato, concorda expressamente que
                outras informações adicionais poderão ser coletadas, tais como:</li>
              <li>(i) código postal;</li>
              <li>(ii) endereço de correspondência; e</li>
              <li>(iii) detalhes referentes ao histórico de transações.</li>
              <li>7.3. Para fins de execução da operação desejada, as informações disponibilizadas serão consideradas
                verdadeiras. Na hipótese de informações falsas serem disponibilizadas, terceiros serão acionados com o
                intuito de apurar a possibilidade de fraude; estes terão acesso às informações disponibilizadas com o
                exclusivo objetivo de verificar a ocorrência ou não da possível fraude.</li>
              <li>7.4. Ao acessar a Plataforma Tutu, mesmo os Usuários anônimos estarão sujeitos à verificação de suas
                informações mínimas, para fins de elaboração de estatísticas referentes aos Usuários Tutu. Ressalta-se que
                o anonimato será mantido.</li>
            </ul>
            <ul>
              <li class="title">8. INFORMAÇÕES QUE COLETAMOS EXCLUSIVAMENTE DOS TOMADORES</li>
              <li>8.1. Você está ciente e, neste ato, concorda que a análise de crédito e a veiculação de sua solicitação
                na Plataforma Tutu depende de diversas informações disponibilizadas pelo Tomador, tais como valor
                solicitado, tempo estimado para pagamento, utilidade pretendida ao crédito solicitado, e 5 histórico do
                Tomador. Para determinar estas informações, é preciso que o Tomador forneça as seguintes informações e,
                neste ato, você concorda que a Tutu poderá coletar, armazenar, tratar e usar as informações a seguir para
                as finalidades dispostas nestes Termos e Condições de Uso e Política de Privacidade:</li>
              <li>(I) Em relação à “Empresa Tomadora”:</li>
              <li class="ml-6">(i) ficha cadastral Tutu preenchida e assinada eletronicamente;</li>
              <li class="ml-6">(ii) firma/denominação social;</li>
              <li class="ml-6">(iii) Contrato Social e comprovante de inscrição no CNPJ/MF;</li>
              <li class="ml-6">(iv) dados financeiros (incluindo declaração de faturamento);</li>
              <li class="ml-6">(v) dados apresentados no plano de negócios da Empresa Tomadora;</li>
              <li class="ml-6">(vi) comprovantes de pagamento da Empresa Tomadora; e</li>
              <li class="ml-6">(vi) telefone comercial e endereço da sede.</li>
              <li>(II) Em relação aos sócios/administradores da Empresa Tomadora:</li>
              <li class="ml-6">(i) ficha cadastral Tutu preenchida e assinada eletronicamente;</li>
              <li class="ml-6">(ii) senha de acesso e outros procedimentos de verificação de identidade;</li>
              <li class="ml-6">(iii) contratos da operação assinados eletronicamente;</li>
              <li class="ml-6">(iv) declaração de pessoa politicamente exposta;</li>
              <li class="ml-6">(v) autorização para consulta perante o Sistema de Informações de Crédito do Banco Central
                (SCR);</li>
              <li class="ml-6">(vi) autorização para consulta na central de riscos do SPC Brasil, do Serasa e de outras
                entidades e/ou ferramentas de análise de crédito ou de prevenção fraudes;</li>
              <li class="ml-6">(vii) cédula de identidade e autoridade emissora;</li>
              <li class="ml-6">(viii) comprovante de inscrição no CPF/MF;</li>
              <li class="ml-6">(ix) declaração de renda mensal; e</li>
              <li class="ml-6">(x) endereço comercial/residencial.</li>
              <li>8.2. Os dados e documentos listados nos itens (I) e (II) também serão solicitados aos Investidores,
                sendo que se o Investidor for uma pessoa física, os critérios do item (II) serão aplicáveis.</li>
              <li>8.3. Você está ciente e, neste ato, concorda que outras informações exigidas por lei, ou a critério
                exclusivo e subjetivo da Tutu poderão ser requeridas e exigidas pela Plataforma Tutu para análise de
                risco, ou outro motivo, para formalização do empréstimo ou investimento. </li>
              <li>8.4. Você desde já concorda que poderemos veicular na Plataforma Tutu ou em redes sociais as seguintes
                informações fornecidas por você e/ou coletadas de terceiros sobre você: score de crédito, perfil de redes
                sociais, entre outros, a fim de possibilitar a análise e decisão dos Investidores, bem como eventualmente
                nos ajudar no processo de cobrança das parcelas de seu empréstimo. </li>
            </ul>
            <ul>
              <li class="title">9. INFORMAÇÕES QUE COLETAMOS EXCLUSIVAMENTE DOS INVESTIDORES</li>
              <li>9.1. Ao se cadastrar na Plataforma Tutu, na qualidade de Investidor, você concorda expressamente que as
                seguintes informações adicionais serão coletadas, armazenadas, tratadas e utilizadas pela Tutu:</li>
              <li>(i) Respostas ao questionários de Suitability da Plataforma Tutu, se aplicável;</li>
              <li>(ii) Informe de rendimentos ou outro documento similar solicitado para fins de comprovação de renda; e
              </li>
              <li>(iii) Outras informações que componham o perfil do Investidor da Plataforma Tutu, nos termos da
                regulação aplicável.</li>
            </ul>
            <ul>
              <li class="title">10. POSSÍVEIS FINALIDADES PARA AS INFORMAÇÕES DISPONIBILIZADAS</li>
              <li>10.1. O uso das informações coletadas e armazenadas exige o processamento de dados que poderá ser feito
                pela própria Tutu. O Usuário expressamente concorda com a transferência desses dados a outros agentes de
                mercado, tais como instituições financeiras, que serão necessárias, única e exclusivamente, na consecução
                das transações realizadas na Plataforma Tutu.</li>
              <li>10.2. O usuário está ciente e consente expressamente que a Tutu, empresas do grupo Tutu ou terceiros
                prestadores de serviços estão autorizados a utilizar as informações disponibilizadas pelos Usuários para
                os seguintes propósitos:</li>
              <li>(i) verificação da identidade do Usuário;</li>
              <li>(ii) prevenir a ocorrência de crimes financeiros e de lavagem de dinheiro;</li>
              <li>(iii) identificar possível fraude;</li>
              <li>(iv) efetuar a análise de crédito dos Tomadores;</li>
              <li>(v) administrar e aprimorar a Plataforma Tutu;</li>
              <li>(vi) tornar a página de acesso da Tutu disponível aos seus usuários e identificar os serviços de seus
                interesses;</li>
              <li>(vii) personalizar o conteúdo, como fazer sugestões ou mostrar contas de usuários a outros usuários com
                base em seus contatos importados da lista de contatos;</li>
              <li>(viii) Personalizar e melhorar a sua experiência na Plataforma Tutu;</li>
              <li>(ix) permitir que você se comunique com outro Usuário;</li>
              <li>(x) realizar controles regulamentares requeridos pela legislação/regulação aplicáveis;</li>
              <li>(xi) contatar os Usuários nas ocasiões necessárias;</li>
              <li>(xii) manter informados quaisquer terceiros possivelmente interessados e/ou relacionados à operação
                desejada; e</li>
              <li>(xiii) garantir a execução da operação desejada.</li>
              <li>10.3. Os dados constante do cadastramento como nome, nome de usuário e dados complementares como
                resultados de análise de riscos e análise de fraude poderão ser disponibilizados publicamente para outros
                Usuários para que se permita a conexão entre Investidores e Tomadores na Plataforma Tutu. </li>
              <li>10.4. A Tutu se compromete a utilizar as informações disponibilizadas somente mediante a anuência dos
                Usuários, que decorrerá da declaração de concordância com estes Termos e Condições de Uso e Política de
                Privacidade.</li>
              <li>10.5. Sem prejuízo de qualquer disposição em contrário nestes Termos e Condições de Uso e Política de
                Privacidade, a Tutu pode manter ou divulgar suas informações caso acredite que tal conduta é razoavelmente
                necessária para o cumprimento de leis, regulamentos, procedimentos legais ou ato/ordem de autoridade
                governamental; para proteger a segurança de qualquer pessoa; resolver problemas relacionados a fraude,
                segurança ou problemas técnicos; ou proteger os direitos e a propriedade da Tutu e dos Usuários. </li>
              <li>10.6. Na eventualidade de a Tutu estar envolvida em processo de falência, fusão, aquisição,
                reorganização ou de venda de ativos, suas informações poderão ser vendidas ou transferidas como parte
                desse processo.</li>
            </ul>
            <ul>
              <li class="title">11. DADOS DE REGISTRO E COOKIES</li>
              <li>11.1. A Tutu utiliza cookies para aperfeiçoar a performance da Plataforma Tutu e fornecer uma
                experiência melhor a seus Usuários. Cookies são pequenos arquivos de dados que são transferidos para o seu
                computador ou dispositivo móvel. A Tutu pode utilizar tanto cookies de sessão como cookies persistentes
                para compreender melhor de que forma você interage com a Plataforma Tutu e os serviços disponibilizados
                pela Tutu, para monitorar a utilização agregada pelos Usuários e redirecionamento de tráfego web na
                Plataforma Tutu, assim como para melhorar e personalizar nossos serviços. Ao deletar nossos cookies ou
                desabilitar futuros cookies, o Usuário não poderá ter acesso a certas áreas ou serviços oferecidos pela
                Plataforma Tutu.</li>
              <li>11.2. Nós recebemos informações quando você interage com nossa Plataforma Tutu ou serviços mesmo se não
                tiver criado uma conta (“Dados de Registro”). Por exemplo, quando você visita o website, acessa os nossos
                serviços, interage com as nossas notificações por e-mail, podemos receber informações sobre você. Estes
                Dados de Registro podem incluir seu endereço IP, tipo de navegador, sistema operacional, página da web de
                origem, páginas da web visitadas, localização, seu operador de celular, informações de dispositivo, termos
                de pesquisa ou informações de cookies. Os Dados de 8 Registro poderão ser utilizados para fazer deduções
                de tópicos que podem interessar a você, para personalizar o conteúdo a você etc. Nós mantemos os Dados de
                Registro conforme necessário para as finalidades descritas nestes Termos e Condições de Uso e Política de
                Privacidade.</li>
              <li>11.3. Quando um visitante acessa qualquer página de nosso site, nossos servidores automaticamente obtêm
                seu nome de domínio e endereço IP, por exemplo. A Tutu utiliza essas informações na administração de seu
                site para examinar seu tráfego agregado, investigar abusos com relação à Plataforma Tutu ou seus usuários
                e/ou garantir o cumprimento da legislação vigente. A Tutu não disponibiliza essas informações a terceiros,
                com exceção das hipóteses previstas em lei, como fornecimento de dados mediante decisão judicial.</li>
              <li>11.4. Dentre as informações obtidas dessa forma pode-se apontar:</li>
              <li>(i) informações relativas ao tipo de assinatura na Plataforma Tutu, a qual está diretamente relacionada
                à operação desejada;</li>
              <li>(ii) detalhes sobre as consultas realizadas na Plataforma Tutu;</li>
              <li>(iii) conteúdo publicado pelo Usuário, mesmo que este não esteja diretamente relacionado a operação
                desejada na Plataforma Tutu;</li>
              <li>(iv) dados técnicos que podem incluir a URL, o endereço IP, ID único do dispositivo, rede e desempenho
                do computador, tipo de navegador, idioma e informações de identificação e informações transacionais que
                possibilitem o gerenciamento de direitos digitais, sendo todas estas informações referentes exclusivamente
                ao Usuário; e </li>
              <li>(v) informação de localização do acesso.</li>
              <li>11.5. Há ainda a possibilidade de utilização de cookies e outras tecnologias similares, com o exclusivo
                intuito de coletar referidas informações adicionais.</li>
            </ul>
            <ul>
              <li class="title">12. POLÍTICA DE COMUNICAÇÃO</li>
              <li>12.1. Quando qualquer Usuário se registra na Plataforma Tutu, ele receberá e-mails para confirmar ações
                e solicitações específicas. Receberá também notificações referentes a etapas importantes do seu
                relacionamento com a Tutu. O Tomador receberá notificações sobre seu pedido de empréstimo pessoal, seus
                pagamentos e o seu balanço e o Investidor receberá informações sobre seu balanço e o status de sua conta,
                por exemplo. Essas notificações transacionais não possuem opções de desligamento (optout), uma vez que são
                enviadas por medidas de segurança pela Plataforma Tutu.</li>
              <li>12.2. Poderemos também responder e-mails enviados por Usuários, se aplicável. Eventualmente enviaremos
                pesquisas e outras comunicações (diretamente ou por empresas parceiras) que julgarmos relevantes para o
                Usuário, mas essas comunicações são de recebimento voluntário e o Usuário poderá programar sua conta para
                não recebê-las (opt-out).</li>
            </ul>
            <ul>
              <li class="title">13. LINKS</li>
              <li>13.1. O Usuário reconhece e concorda que a Tutu não tem controle sobre sites ou recursos externos
                acessados através de links em sua página de acesso. Assim, a Plataforma Tutu não endossa e não se 9
                responsabiliza por qualquer conteúdo de terceiros, propaganda, produtos e serviços contidos em anúncios de
                terceiros em seu site.</li>
              <li>13.2. A Tutu se exime também de qualquer responsabilidade pelos termos de uso e políticas de privacidade
                desses websites ou outros materiais contidos ou disponibilizados neles. Tampouco compartilha, subscreve,
                monitora, valida ou aceita a forma como os sites ou as ferramentas de armazenamento de conteúdo a que
                referem os links postados em sua página coletam, processam e transferem suas informações pessoais. Neste
                sentido, sugerimos que o Usuário se informe sobre os termos de uso, políticas de privacidade e demais
                documentos que venham a reger seu acesso a sites de terceiros antes de fornecer qualquer tipo de
                informação.</li>
              <li>13.3. O Usuário expressamente reconhece e concorda que a Tutu não será responsável, direta ou
                indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados pela, ou
                relacionados à, confiança depositada nas informações, bens e serviços disponíveis no ou através de tais
                sites de terceiros.</li>
            </ul>
            <ul>
              <li class="title">14. SEGURANÇA</li>
              <li>14.1 O acesso à Plataforma Tutu foi instituído com o intuito de proteger as informações de seus
                Usuários. O Usuário é responsável pela veracidade, exatidão e atualização de referidos dados. A Senha
                Pessoal escolhida deve ser de acesso exclusivo do Usuário, pessoal e intransferível, ficando a sua guarda
                sob a sua responsabilidade. A Senha Pessoal deve apresentar caracteres variados que aumentem sua
                segurança. </li>
              <li>14.2. Apesar de a Tutu tomar as precauções necessárias, esta não se responsabiliza por eventuais fraudes
                no sistema, as quais possam resultar no acesso de terceiros não autorizados às informações
                disponibilizadas pelos usuários. </li>
              <li>14.3. A Tutu limita o acesso aos dados e informações pessoais coletados aos seus próprios colaboradores
                que possam ter necessidade de conhecê-las para exercer suas funções e mantém controles de acessos nos
                termos da lei.</li>
              <li>14.4. A Tutu conta com recursos de proteção física, eletrônica e procedimental, que atendem aos padrões
                legais nacionais e internacionais de proteção a informações pessoais, tais como mecanismos de autenticação
                de acesso com sistemas de autenticação dupla assegurando a individualização do responsável pelo tratamento
                dos registros, inventário detalhado de acessos à Plataforma Tutu, uso de soluções de gestão de registros
                por técnicas que garantem a inviolabilidade dos dados.</li>
            </ul>
            <ul>
              <li class="title">15. ACESSO E ATUALIZAÇÃO DAS INFORMAÇÕES DO USUÁRIO</li>
              <li>15.1. Tanto Tomadores quanto Investidores podem acessar as informações disponibilizadas ou alterálas a
                qualquer momento. Para isso, basta acessar a plataforma nas páginas de cadastro de Investidor/Tomador
                disponíveis em <a class="underline text-blue-700" target="_blank"
                  href="https://www.tutudigital.com.br">www.tutudigital.com.br</a>.</li>
            </ul>
            <ul>
              <li class="title">16. FALE CONOSCO</li>
              <li>16.1 Na ocorrência de quaisquer dúvidas ou comentários sobre estes Termos e Condições de Uso e Política
                de Privacidade e autorização para divulgação de informações dos Tomadores, por favor entrem em contato em
                <span class="undeline text-blue-700">quero@tutudigital.com.br</span>
              </li>
            </ul>
            <ul>
              <li class="title">17. TERMO DE CONCORDÂNCIA</li>
              <li>17.1. AO ADERIR OS TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE, O USUÁRIO ESTÁ EXPRESSAMENTE
                AUTORIZANDO A TUTU A UTILIZAR E COMPARTILHAR COM OUTRAS EMPRESAS INTEGRANTES DO GRUPO TUTU, BEM COMO COM
                PARCEIROS COMERCIAIS CONFIÁVEIS E TERCEIROS PRESTADORES DE SERVIÇOS, OS QUAIS PODEM OU NÃO ESTAR
                LOCALIZADOS NO BRASIL, QUALQUER INFORMAÇÃO PRESTADA À TUTU, DESDE QUE ESSE COMPARTILHAMENTO SEJA
                NECESSÁRIO PARA PRESTAÇÃO DOS SERVIÇOS CONTRATADOS PELO USUÁRIO. O USUÁRIO RECONHECE A IMPORTÂNCIA DO
                COMPARTILHAMENTO DE TAIS INFORMAÇÕES, VISANDO EXCLUSIVAMENTE A ADEQUADA PRESTAÇÃO DOS SERVIÇOS CONTRATADOS
                POR MEIO DA PLATAFORMA TUTU E OBTENÇÃO DA OPERAÇÃO DESEJADA. CONCORDA TAMBÉM QUE, AO ADERIR OS TERMOS E
                CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE O CONSENTIMENTO DO USUÁRIO REFERE-SE À TOTALIDADE DA DURAÇÃO DE
                SUA RELAÇÃO COM A TUTU.</li>
            </ul>
            <ul>
              <li class="title">18. AVISOS LEGAIS E LIMITAÇÕES DE RESPONSABILIDADE </li>
              <li>18.1. Desde que não previsto de outra forma nos termos e condições de Investidores e Tomadores, seu
                acesso para e uso da Plataforma Tutu e de outros serviços disponibilizados pela Tutu são de sua
                responsabilidade. Você entende e concorda que a Plataforma Tutu e outros serviços disponibilizados pela
                Tutu são fornecidos a você “TAL QUAL” e “CONFORME DISPONÍVEIS”. Sem prejuízo do referido anteriormente e
                na extensão máxima permitida pela legislação aplicável, A TUTU EXCLUI TODAS E QUAISQUER GARANTIAS E
                CONDIÇÕES, SEJAM IMPLÍCITAS OU EXPLÍCITAS, DE ADEQUAÇÃO PARA COMERCIALIZAÇÃO, DE ADEQUAÇÃO A UMA
                FINALIDADE ESPECÍFICA OU DE NÃO VIOLAÇÃO DE DIREITOS. A Tutu não presta qualquer tipo de garantia ou
                declaração e exclui qualquer responsabilidade: (i) pela completude, exatidão, disponibilidade,
                tempestividade, segurança ou confiabilidade da Plataforma Tutu; (ii) por qualquer dano sofrido pelo seu
                sistema de computador, perda de dados, ou qualquer outro tipo de dano resultante do seu acesso ou
                utilização da Plataforma Tutu; (iii) pela exclusão, falha de armazenamento ou transmissão de quaisquer
                conteúdos e outras comunicações mantidas pela Plataforma Tutu; e (iv) pela adequação da Plataforma Tutu às
                suas exigências ou pela disponibilidade da Plataforma Tutu de forma ininterrupta, segura ou livre de erros
                ou defeitos. Nenhum conselho ou informação, seja escrito ou oral, obtido pela Tutu criará qualquer
                garantia ou declaração que não esteja expressamente estabelecida nestes Termos e Condições de Uso e
                Política de Privacidade.</li>
              <li>18.2. Limitação de Responsabilidade</li>
              <li>18.2.1 A TUTU NÃO SERÁ RESPONSÁVEL POR QUAISQUER DANOS INDIRETOS, INCIDENTAIS, ESPECIAIS, CONSEQUENCIAIS
                OU PUNITIVOS, ASSIM COMO POR QUALQUER PERDA DE LUCROS OU RECEITAS, SEJA DIRETA OU INDIRETA, OU POR
                QUALQUER PERDA DE DADOS, USO, FUNDO DE COMÉRCIO OU QUAISQUER PERDAS INTANGÍVEIS, DECORRENTES DE (i) SEU
                ACESSO, UTILIZAÇÃO OU INCAPACIDADE DE ACESSO OU UTILIZAÇÃO DA PLATAFORMA TUTU; (ii) QUALQUER CONDUTA OU
                CONTEÚDO DE TERCEIROS NOS SERVIÇOS INCLUINDO, SEM LIMITAÇÃO, QUALQUER CONDUTA DIFAMATÓRIA, OFENSIVA OU
                ILEGAL DE OUTROS USUÁRIOS OU TERCEIROS; (iii) QUALQUER CONTEÚDO OBTIDO ATRAVÉS DA PLATAFORMA TUTU; OU (iv)
                ACESSO, UTILIZAÇÃO OU ALTERAÇÃO NÃO AUTORIZADOS DE SUAS TRANSMISSÕES OU CONTEÚDO. AS LIMITAÇÕES DESTA
                SUBCLÁUSULA SÃO APLICÁVEIS A QUALQUER TEORIA DE RESPONSABILIDADE, SEJA COM BASE EM GARANTIA, CONTRATO,
                DISPOSIÇÃO LEGAL OU REGULAMENTAR, RESPONSABILIDADE CIVIL (INCLUINDO CULPA) OU DE OUTRA FORMA, TUDO ISSO
                INDEPENDENTEMENTE DE A TUTU TER SIDO OU NÃO TER SIDO 11 INFORMADA DA POSSIBILIDADE DE OCORRÊNCIA DE TAIS
                DANOS, E AINDA QUE QUALQUER DAS FORMAS DE REPARAÇÃO DESCRITAS ACIMA NÃO TENHA CUMPRIDO A SUA FINALIDADE
                ESSENCIAL.</li>
              <li>18.3. ALGUMAS JURISDIÇÕES NÃO PERMITEM A EXCLUSÃO DE GARANTIA IMPLÍCITAS OU A LIMITAÇÃO DA VIGÊNCIA DAS
                GARANTIAS IMPLÍCITAS, PORTANTO, A PRESENTE CLÁUSULA PODE NÃO SE APLICAR A VOCÊ EM SUA TOTALIDADE, MAS IRÁ
                SE APLICAR AO LIMITE MÁXIMO PERMITIDO PELA LEI APLICÁVEL.</li>
            </ul>
            <ul>
              <li class="title">19. OUTROS TERMOS IMPORTANTES </li>
              <li>19.1 A nulidade ou invalidade de qualquer das disposições destes Termos e Condições de Uso e Política de
                Privacidade não prejudicará a validade e eficácia das demais cláusulas ou destes Termos e Condições de Uso
                e Política de Privacidade Caso alguma decisão judicial em caráter definitivo pronunciese sobre a
                invalidade ou ineficácia de qualquer das disposições destes Termos e Condições de Uso e Política de
                Privacidade as partes deverão substituir a regra inquinada por outra que, sendo lícita, permita sejam
                alcançados, na maior extensão possível, os resultados práticos inicialmente visados</li>
              <li>19.2 Todas as renúncias, indenizações e exclusões nestes Termos e Condições de Uso e Política de
                Privacidade sobreviverão à rescisão do acordo entre nós por qualquer razão. </li>
              <li>19.3 Podemos, no todo ou em parte, transigir, renunciar ou adiar, na nossa absoluta discrição, qualquer
                direito concedido a nós nestes Termos e Condições de Uso e Política de Privacidade sem de modo algum
                prejudicar ou afetar os nossos direitos em relação a estes Termos e Condições de Uso e Política de
                Privacidade.</li>
              <li>19.4 As partes reconhecem que: (i) o não exercício, por qualquer delas, ou o atraso no exercício de
                qualquer direito que lhes seja assegurado por este instrumento ou por lei não constituirá novação ou
                renúncia de tal direito, nem prejudicará o seu eventual exercício, a qualquer tempo; (ii) a renúncia, por
                qualquer das partes, de algum dos direitos ou poderes previstos nestes Termos e Condições de Uso e
                Política de Privacidade somente será válido se formalizado por escrito.</li>
              <li>19.5 Estes Termos e Condições de Uso e Política de Privacidade são regidos pelas leis da República
                Federativa do Brasil.</li>
              <li>19.6. Quaisquer dúvidas ou controvérsias oriundas destes Termos e Condições serão dirimidas no Foro da
                Cidade de São Paulo, Estado de São Paulo, com a exclusão de quaisquer outros, por mais privilegiados que
                sejam.</li>
              <li>Essa versão da Política de Privacidade foi atualizada em 27 de outubro de 2017. </li>
              <li>[ ] CONCORDO expressamente com o inteiro teor dos Termos e Condições de Uso e Política de Privacidade
                ficando desde já obrigado e sujeito aos direitos e obrigações oriundos deste instrumento.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms-politics-modal',
  methods: {
    close() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative h-20 w-full bg-white z-50 flex flex-col pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-11/12 h-160

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2 text-lg

.content_modal
  @apply flex flex-col w-full px-4 overflow-auto
  @screen lg
    @apply px-8

.row
  @apply w-full flex justify-center
  @screen md
    @apply justify-center mt-6

.title
  @apply text-xl text-primary font-bold mb-3

ul li
  @apply leading-normal mb-2

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>
